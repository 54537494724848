export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAynKF5joA-_wpax9jzatonSZgxSE-MaRQ",
  appId: "1:810900069450:web:0f69447751bb45cac59ab3",
  authDomain: "nova-a3-ind.firebaseapp.com",
  measurementId: "G-PXKR7KDTEP",
  messagingSenderId: "810900069450",
  projectId: "nova-a3-ind",
  storageBucket: "nova-a3-ind.appspot.com",
};

export const FIREBASE_MESSAGING_VAPID_KEY =
  "BHAAggUsRBF-E-GWYHh8vY3A4r6kZMgHwrQ7qs1a6jXtU6tHxLq9WObBW-HalHDzA9YQ74U7mjiu-9nsKb0vabU";

export const FIREBASE_APP_CHECK_SITE_KEY =
  "6LeUhpUdAAAAANDd0bAkXHIxAWwd96o8PGUpdrKK";
