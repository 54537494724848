import { PageTitle } from "@components";
import React from "react";

export function MaintProjectsCalendarPage(): JSX.Element {
  return (
    <>
      <PageTitle>Manutenção • Calendário</PageTitle>
      Em breve...
    </>
  );
}
